// npm
import React from 'react'
import { Box, Stack, StackProps, styled, useTheme } from '@mui/material'
import SVG from 'react-inlinesvg'

// components
import InternalButtonLink from '@atoms/buttonLinks/InternalButtonLink'

interface Props extends StackProps {
  useAlternateColor: boolean
  iconUrl: string
  heading: string
  slug?: string
  apiKey?: string
  buttonLabel?: string
  vCenter?: boolean
}

const StyledSVG = styled(SVG)(({ theme }) => ({
  path: {
    fill: theme.palette.common.white,
  },
  rect: {
    ['&:not(.svg-icon-bg)']: {
      fill: theme.palette.common.white,
    },
  },
  polygon: {
    fill: theme.palette.common.white,
  },
}))

const IconCard = ({
  useAlternateColor,
  iconUrl,
  heading,
  buttonLabel,
  slug,
  apiKey,
  vCenter,
  ...props
}: Props) => {
  const theme = useTheme()
  const hasButton = buttonLabel && slug && apiKey
  return (
    <Stack
      alignItems="center"
      justifyContent="stretch"
      sx={{
        boxSizing: 'border-box',
        textAlign: 'center',
        background: `linear-gradient(180deg, ${
          useAlternateColor
            ? theme.palette.secondary.main
            : theme.palette.secondaryDarker
        } 0, ${theme.palette.secondary.dark} 100%)`,
      }}
      pt={5}
      px={5}
      pb={vCenter ? 5 : 12}
      height="100%"
      {...props}
    >
      <Stack
        alignItems="center"
        justifyContent="center"
        bgcolor="rgba(255,255,255,0.05)"
        mb={3}
        sx={{
          borderRadius: '50%',
          width: '130px',
          height: '130px',
          padding: '30px',
          color: 'white',
        }}
      >
        <StyledSVG src={iconUrl} />
      </Stack>
      <Box component="h3" typography="body1" mb={hasButton ? 3 : 0}>
        {heading}
      </Box>
      {hasButton && (
        <InternalButtonLink
          sx={{ marginTop: 'auto' }}
          buttonVariant="text"
          slug={slug}
          apiKey={apiKey}
          aria-label={`${buttonLabel} about ${heading}`}
        >
          {buttonLabel}
          <Box
            component="span"
            sx={{ display: 'block', width: 0, height: 0, overflow: 'hidden' }}
          >
            {' '}
            about {heading}
          </Box>
        </InternalButtonLink>
      )}
    </Stack>
  )
}

export default React.memo(IconCard)
